import classNames from "classnames";
import * as React from "react";
import { HeadFC, Link } from "gatsby";
import Seo from "../components/seo";
import NotFoundIcon from "../images/icons/not_found.svg";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main
        className={classNames(
          "flex-grow relative container mx-auto flex flex-col items-center justify-center"
        )}
      >
        <NotFoundIcon className="w-48 h-48" />
        <hr className="mt-8 w-48 h-0.5 bg-primary" />
        <p className="mt-8 text-primary-300">
          The page you were looking for does not exist
        </p>
        <Link
          to={"/"}
          className="button button-primary w-full max-w-[34rem] mt-8"
        >
          Home
        </Link>
      </main>
    </div>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <Seo />;
